<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <h3>مدیریت زمان‌های آزاد پزشک</h3>
        <v-spacer></v-spacer>
        <v-btn class="primary-btn" @click="addFreeDialog = true"
          >افزودن زمان آزاد</v-btn
        ></v-card-title
      >
      <v-card class="cards pa-5">
        <v-row class="px-4">
          <v-col cols="12" md="4" class="d-flex justify-content-center">
            <div
              class="cyan lighten-5 pa-5"
              style="border-radius: 20px; width: 90%"
            >
              <div>
                <v-icon small class="green--text text--darken-4"
                  >mdi-circle</v-icon
                >
                <span class="h4 grey--text text--darken-3">
                  کلیه زمان‌ها در این روز آزاد هستند.
                </span>
              </div>
              <div class="my-3">
                <v-icon small class="orange--text">mdi-circle</v-icon>
                <span class="h4 grey--text text--darken-3">
                  در این روز کلیه زمان‌‌ها پر نشده‌اند.
                </span>
              </div>
              <div>
                <v-icon small class="red--text">mdi-circle</v-icon>
                <span class="h4 grey--text text--darken-3">
                  کلیه زمان‌ها در این روز پر هستند.
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-4" style="justify-content: space-between">
          <v-col lg="5" md="12" sm="12" cols="12">
            <div class="calendar">
              <div
                class="header d-flex justify-content-center align-items-center"
              >
                <div
                  v-if="year != currentYear || month != currentMonth"
                  class="calendar-btn"
                >
                  <v-btn
                    elevation="0"
                    height="30"
                    class="white fs-xsmall pa-0"
                    @click="showNow"
                    ><span class="c-primary">اکنون</span></v-btn
                  >
                </div>
                <v-row class="px-4">
                  <v-col class="text-center">
                    <span
                      id="yearPicker"
                      class="year white--text mb-1 pointer h1"
                      ><v-icon class="me-1 white--text"
                        >fa fa-pencil-square-o</v-icon
                      >
                      {{ year }}
                    </span>
                  </v-col>
                </v-row>
                <date-picker
                  element="yearPicker"
                  v-model="year"
                  type="year"
                  :auto-submit="true"
                  @change="getNumberOfDays()"
                >
                </date-picker>
              </div>
              <div class="month py-2">
                <span id="monthPicker" class="mb-0 pointer primary--text h4">
                  <v-icon class="me-1 primary--text"
                    >fa fa-pencil-square-o</v-icon
                  >
                  {{ persianMonthName(month) }}
                </span>
              </div>
              <date-picker
                element="monthPicker"
                v-model="month"
                type="month"
                :auto-submit="true"
                @change="getNumberOfDays()"
              >
              </date-picker>

              <div class="fit-content pa-5">
                <div>
                  <div
                    v-for="(day, index) in weekDays"
                    :key="index"
                    class="weekday-box d-inline-block blue-grey--text text--darken-3"
                    style="transform: rotate(-50deg)"
                  >
                    {{ day }}
                  </div>
                </div>

                <div
                  v-for="(number, index) in numberOfDay"
                  :key="index"
                  class="day-box d-inline-block"
                  :class="
                    checkDate(number) ? computedDayColor(number) : 'disabled'
                  "
                  :style="
                    index == 0
                      ? 'margin-right:' +
                        (deviceType == 'mobile' ? 60 : 57) *
                          numberOfWeekDay.value +
                        'px;'
                      : ''
                  "
                  @click="selectDate(number)"
                >
                  <div
                    class="semi-number"
                    v-if="isSemi(number) && checkDate(number)"
                  >
                    <span>{{ computedLeftTimes(number) }}</span>
                  </div>
                  {{ number }}
                </div>
              </div>
            </div>

            <!--<ul class="ps-1">
                    <li class="h3 green--text">
                      کلیه زمان‌های ثبت شده در این روز آزاد هستند.
                    </li>
                    <li class="h3 orange--text">
                      در این روز کلیه زمان‌‌ها پر نشده‌اند.
                    </li>
                    <li class="h3 red--text">
                      کلیه زمان‌های ثبت شده در این روز پر هستند.
                    </li>
                  </ul>-->
          </v-col>

          <transition name="fade">
            <v-col v-if="showTimes" lg="7" md="12" sm="12" cols="12">
              <div class="h4 mb-4">
                <span class="h3 grey--text text--darken-2">
                  <v-icon class="primary--text"
                    >mdi-comment-alert-outline</v-icon
                  >
                  برای مشاهده جزئیات درخواست‌هایی که رزرو شده‌اند، ابتدا درخواست
                  باید توسط پزشک مربوطه انجام شود.
                </span>
              </div>
              <div class="h4 mb-4">
                <span class="h3 grey--text text--darken-2">
                  <v-icon class="primary--text"
                    >mdi-comment-alert-outline</v-icon
                  >
                  به منظور مشاهده جزئیات درخواست انجام شده، بر روی وضعیت کلیک کنید.
                </span>
              </div>
              <p class="font-weight-bold primary--text">
                {{
                  " زمان‌های پزشک در تاریخ" +
                  " " +
                  this.day +
                  " " +
                  persianMonthName(this.selectedMonth) +
                  " " +
                  this.selectedyear
                  + ":"
                }}
              </p>
              <v-card class="date-result pa-5">
                <b-table
                  responsive
                  show-empty
                  :fields="Fields"
                  :items="allTimes"
                  empty-text="زمانی برای نمایش وجود ندارد"
                  :current-page="CurrentPage"
                  :tbody-tr-class="rowClass"
                  small
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.value }}
                    </div>
                  </template>
                  <template v-slot:cell(patientName)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.value ? data.value : "-" }}
                    </div>
                  </template>
                  <template v-slot:cell(status)="data">
                    <div style="text-align: center; vertical-align: middle">
                      <v-btn
                        v-if="
                          data.item.status == 'catched' && data.item.visitId
                        "
                        class="primary-btn py-2"
                        :to="'/admin/editrequest/' + data.item.visitId"
                        target="_blank"
                        >انجام شده</v-btn
                      >
                      <span
                        v-else-if="
                          data.item.status == 'catched' && !data.item.visitId
                        "
                        >رزرو شده</span
                      >
                      <span v-else-if="data.item.status == 'free'"> آزاد </span>
                      <span v-else-if="data.item.status == 'passed'"> زمان گذشته </span>
                    </div>
                  </template>
                  <!--<template v-slot:cell(operation)="data">
                    <div style="text-align: center; vertical-align: middle">
                      <v-btn
                        v-if="data.item.status == 'catched'"
                        class="primary-btn py-3"
                        :to="'/admin/editrequest/' + data.item.visitId"
                        target="_blank"
                        :disabled="!data.item.visitId"
                      >
                      <v-icon>mdi-file-eye-outline</v-icon>
                        مشاهده جزییات
                      </v-btn>
                       <div
                        class="disabled-btn mx-auto py-1"
                       v-if="data.item.status == 'free'"
                      >
                        <span> فاقد عملیات </span>
                      </div>
                    </div>
                  </template>-->
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                    </div>
                  </template>
                  <div slot="table-busy" class="text-center primary--text my-2">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      class="align-middle"
                    ></v-progress-circular>
                  </div>
                </b-table>
              </v-card>
            </v-col>
          </transition>
        </v-row>
      </v-card>
      <v-dialog v-model="colorsGuidance" width="500px">
        <v-card class="pa-5" style="border-radius: 20px">
          <v-card-title>
            <span class="mx-auto c-primary"> راهنمای رنگ‌ها </span>
          </v-card-title>
          <v-container class="text-right">
            <v-row class="px-5">
              <v-col>
                <ul class="h2">
                  <li class="green--text text--lighten-1 pb-3">زمان‌ آزاد</li>
                  <li class="red--text text--darken-1 pb-3">
                    زمان ملاقات ثبت شده
                  </li>
                  <li class="grey--text">زمان گذشته</li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addFreeDialog" width="600px">
        <v-card style="border-radius: 20px" class="px-5 py-3">
          <v-card-title class="ps-2">
            <span class="h1"> افزودن زمان آزاد</span>
          </v-card-title>
          <v-card-text class="p-0">
            <v-container>
              <!-- inja -->
              <v-row>
                <v-col>
                  <span id="freeDateFrom">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="freeDateFrom"
                      :editable="true"
                      class="date-input"
                      label="تاریخ از"
                    >
                    </v-text-field>
                  </span>
                  <date-picker
                    v-model="freeDateFrom"
                    element="freeDateFrom"
                    color="#00a7b7"
                    :min="currentDate"
                  />
                </v-col>
                <v-col>
                  <span id="freeDateTo">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="freeDateTo"
                      :editable="true"
                      class="date-input"
                      label="تاریخ تا"
                    >
                    </v-text-field>
                  </span>
                  <date-picker
                    v-model="freeDateTo"
                    element="freeDateTo"
                    color="#00a7b7"
                    :min="currentDate"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span id="freeTimeFrom">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="freeTimeFrom"
                      :editable="true"
                      class="date-input"
                      label="ساعت از"
                    >
                    </v-text-field>
                  </span>
                  <date-picker
                    v-model="freeTimeFrom"
                    element="freeTimeFrom"
                    color="#00a7b7"
                    type="time"
                  />
                </v-col>
                <v-col>
                  <span id="freeTimeTo">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="freeTimeTo"
                      :editable="true"
                      class="date-input"
                      label="ساعت تا"
                    >
                    </v-text-field>
                  </span>
                  <date-picker
                    v-model="freeTimeTo"
                    element="freeTimeTo"
                    color="#00a7b7"
                    type="time"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="ms-auto">
              <v-btn
                class="mb-2 primary-btn"
                :loading="isBusy"
                @click="addNew()"
                >ثبت
              </v-btn>

              <v-btn
                class="red-btn mb-2 mr-2 text-light"
                @click="addFreeDialog = false"
                >لغو
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showResultDialog" width="600">
        <v-card style="border-radius: 20px" class="modal-card px-3 py-3">
          <v-card-title>
            <span class="h1 primary--text">نتیجه افزودن زمان آزاد</span>
          </v-card-title>
          <v-card-text class="pa-5">
            <v-container>
              <v-row>
                <v-col class="h3 font-weight-bold primary--text"
                  >انجام شده: {{ result.done }}</v-col
                >
                <v-col class="h3 font-weight-bold primary--text"
                  >انجام نشده: {{ result.undone }}</v-col
                >
              </v-row>
              <hr />
              <div v-for="(item, index) in result.newResults" :key="index">
                <v-row>
                  <v-col class="h3">زمان آغاز:</v-col>
                  <v-col class="h3">{{ item.startTime }}</v-col>
                </v-row>
                <v-row>
                  <v-col class="h3">زمان پایان:</v-col>
                  <v-col class="h3">{{ item.finishTime }}</v-col>
                </v-row>
                <v-row>
                  <v-col class="h3">وضعیت:</v-col>
                  <v-col class="h3">{{ item.text }}</v-col>
                </v-row>
                <hr />
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="ms-auto">
              <v-btn color="primary-btn" @click="showResultDialog = false">
                <v-icon class="me-1">mdi-close-outline</v-icon>
                بستن
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      moment: moment,
      Busy: true,
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      pageLoaded: false,
      deviceType: "",
      userId: "",
      showMyCalendar: true,
      startDate: moment(new Date()).format("jYYYY/jMM/jDD"),
      endDate: moment(new Date()).format("jYYYY/jMM/jDD"),
      monthsName: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      daysOfWeek: [
        {
          text: "یکشنبه",
          value: 1,
        },
        {
          text: "دوشنبه",
          value: 2,
        },
        {
          text: "سه‌شنبه",
          value: 3,
        },
        {
          text: "چهارشنبه",
          value: 4,
        },
        {
          text: "پنجشنبه",
          value: 5,
        },
        {
          text: "جمعه",
          value: 6,
        },
        {
          text: "شنبه",
          value: 0,
        },
      ],

      currentYear: moment(new Date()).format("jYYYY"),
      currentMonth: moment(new Date()).format("jMM"),
      year: moment(new Date()).format("jYYYY"),
      month: moment(new Date()).format("jMM"),
      day: moment(new Date()).format("jDD"),
      selected: {},
      selectedyear: "",
      selectedMonth: "",
      numberOfDay: 0,
      numberOfWeekDay: 0,
      allDates: [],
      allTimes: [],
      showTimes: false,
      schedules: [],
      colorsGuidance: false,
      isBusy: false,
      freeDateFrom: moment(new Date()).format("jYYYY-jMM-jDD"),
      freeDateTo: moment(new Date()).format("jYYYY-jMM-jDD"),
      freeTimeFrom: moment(new Date()).format("HH:mm"),
      freeTimeTo: moment(new Date()).add(2, "hours").format("HH:mm"),
      addFreeDialog: false,
      showResultDialog: false,
      result: {},
      removeFreeDialog: false,
      timeLoading: false,
      calendarInfo: false,
      Fields: [
        { key: "patientName", label: "نام بیمار" },
        { key: "time", label: "ساعت ملاقات" },
        { key: "status", label: "وضعیت" },
      ],
      CurrentPage: 1,
      //PerPage: 4,
      //perPageOptions: [
      //  { value: 10, text: "10" },
      //  { value: 25, text: "25" },
      //  { value: 50, text: "50" },
      //  { value: 100, text: "100" },
      //],
      TotalRows: "",
    };
  },
  computed: {
    selectedDay() {
      let selected = "";
      this.schedules.forEach((day) => {
        let stringDay = JSON.stringify(day);
        if (stringDay.includes("انتخاب شده")) {
          selected = day;
        }
      });
      return selected;
    },
    requestedTimes() {
      if (this.selectedDay) {
        let selectedTimes = this.selectedDay.times.filter(
          (x) => x.status == "انتخاب شده"
        );
        return selectedTimes;
      } else {
        return [];
      }
    },
    weekDays() {
      return [
        "شنبه",
        "یکشنبه",
        "دوشنبه",
        "سه‌شنبه",
        "چهارشنبه",
        "پنجشنبه",
        "جمعه",
      ];
    },
    //checkPassedDate() {
    //  let passedDates = [];
    //  this.allDates.length
    //    ? this.allDates.forEach((x) => {
    //        x >= moment(new Date()).format("jYYYY/jMM/jDD")
    //          ? passedDates.push(x)
    //          : "";
    //      })
    //    : "";
    //  return passedDates;
    //},
  },
  methods: {
    computedDayColor(number) {
      let chooseColor;
      if (number < 10) {
        chooseColor = this.schedules.filter(
          (x) => x.date == this.year + "/" + this.month + "/0" + number
        )[0].color;
      } else if (number >= 10) {
        chooseColor = this.schedules.filter(
          (x) => x.date == this.year + "/" + this.month + "/" + number
        )[0].color;
      }

      if (chooseColor == "none") {
        return "freeTime";
      } else if (chooseColor == "all") {
        return "busyTime";
      } else if (chooseColor == "semi") {
        return "semiTime";
      }
    },
    isSemi(number) {
      let chooseColor;
      if (number < 10) {
        chooseColor = this.schedules.filter(
          (x) => x.date == this.year + "/" + this.month + "/0" + number
        )[0]
          ? this.schedules.filter(
              (x) => x.date == this.year + "/" + this.month + "/0" + number
            )[0].color
          : "";
      } else if (number >= 10) {
        chooseColor = this.schedules.filter(
          (x) => x.date == this.year + "/" + this.month + "/" + number
        )[0]
          ? this.schedules.filter(
              (x) => x.date == this.year + "/" + this.month + "/" + number
            )[0].color
          : "";
      }

      if (chooseColor == "semi") {
        return true;
      } else {
        return false;
      }
    },
    computedLeftTimes(number) {
      let times;
      if (number < 10) {
        times = this.schedules
          .filter(
            (x) => x.date == this.year + "/" + this.month + "/0" + number
          )[0]
          .times.filter((y) => y.status == "free");
      } else if (number >= 10) {
        times = this.schedules
          .filter(
            (x) => x.date == this.year + "/" + this.month + "/" + number
          )[0]
          .times.filter((y) => y.status == "free");
      }
      return times.length;
    },
    docVisits() {
      this.isMounted = false;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/visitTimes/adminDoctorAllVisitTimes",
          {
            userId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            //doctor scheduls
            this.schedules = res.data;
            var currentDate = moment(new Date()).format("jYYYY/jMM/jDD");
            var currentTime = moment(new Date()).format("HH:mm");
            setTimeout(() => {
              this.schedules.forEach((x) => {
                if (x.date == currentDate) {
                  x.times.forEach((y) => {
                    let startTime = y.time.split(" - ")[0];
                    startTime < currentTime && y.status != "catched"
                      ? (y.status = "passed")
                      : "";
                  });
                }
              });

              this.schedules.forEach((x) => {
                this.allDates.push(x.date);
              });

              this.isMounted = true;
            }, 300);
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    selectDate(number) {
      if (number < 10) {
        this.day = "0" + number;
      } else {
        this.day = number;
      }
      this.selectedyear = this.year;
      this.selectedMonth = this.month;

      this.allTimes = this.schedules.filter(
        (x) => x.date == this.year + "/" + this.month + "/" + this.day
      )[0].times;
      this.CurrentPage = 1;
      this.TotalRows = this.allTimes.length;
      this.showTimes = true;
    },
    persianMonthName(month) {
      let monthName;
      monthName = this.monthsName[Number(month) - 1];
      return monthName;
    },
    getNumberOfDays() {
      if (1 <= parseInt(this.month) && parseInt(this.month) < 7) {
        this.numberOfDay = 31;
      } else if (7 <= parseInt(this.month) && parseInt(this.month) != 12) {
        this.numberOfDay = 30;
      } else if (parseInt(this.month) == 12) {
        let isLead = moment(this.year + "/12/30", "jYYYY/jMM/jDD").isValid();
        if (isLead) {
          this.numberOfDay = 30;
        } else this.numberOfDay = 29;
      }

      if (parseInt(this.day) > parseInt(this.numberOfDay)) {
        this.day = this.numberOfDay;
      }
      let date = this.year + "/" + this.month + "/" + "01";

      let day = moment(date, "jYYYY/jMM/jDD").format("d");
      this.numberOfWeekDay = this.daysOfWeek[day];

      // alert(this.numberOfWeekDay);
      // alert(this.numberOfDay);
    },
    checkDate(number) {
      if (number < 10) {
        if (
          this.allDates.includes(this.year + "/" + this.month + "/0" + number)
        ) {
          return true;
        } else return false;
      } else {
        if (
          this.allDates.includes(this.year + "/" + this.month + "/" + number)
        ) {
          return true;
        } else return false;
      }
    },
    showNow() {
      this.year = this.currentYear;
      this.month = this.currentMonth;
      this.getNumberOfDays();
    },
    openRemoveModal(item) {
      this.selected = item;
      this.removeFreeDialog = true;
    },
    addNew() {
      this.isBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/visitTimes/newVisitTime",
          {
            userId: this.$route.params.id,
            startDate: this.freeDateFrom + this.freeTimeFrom,
            endDate: this.freeDateTo + this.freeTimeTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.isBusy = false;
          if (res.status == 201) {
            this.addFreeDialog = false;

            this.freeDateFrom = moment(new Date()).format("jYYYY-jMM-jDD");
            this.freeDateTo = moment(new Date()).format("jYYYY-jMM-jDD");
            this.freeTimeFrom =
              moment(new Date().getHours()) +
              ":" +
              moment(new Date().getMinutes());
            this.freeTimeTo = moment(new Date())
              .add(2, "hours")
              .format("HH:mm");
            this.toast("زمان مورد نظر با موفقیت به ثبت رسید.", "success");
            this.result = res.data;
            this.showResultDialog = true;
            this.docVisits();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });
    },
    removeFree() {
      this.vLoading = true;
      this.timeLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/visitTimes/deleteVisitTime",
          {
            visitTimeId: this.selected.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.vLoading = false;
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.removeFreeDialog = false;
            this.docVisits();
            setTimeout(() => {
              this.allTimes = this.schedules.filter(
                (x) => x.date == this.year + "/" + this.month + "/" + this.day
              )[0].times;
              this.timeLoading = false;
            }, 500);
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.timeLoading = false;
          }
        })
        .catch((err) => {
          this.vLoading = false;
          this.timeLoading = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    rowClass(item) {
      if (item && item.status == "free") return "table-row";
    },
  },
  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.userId = this.$route.params.id;
    this.docVisits();
    this.getNumberOfDays();
  },
};
</script>
<style lang="scss">
.modal-card {
  max-height: 580px;
  overflow-y: scroll;
}
.calendar {
  user-select: none;
  border-radius: 17px !important;
  float: right;
  width: 430px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%);

  .header {
    background-color: #00a7b7;
    padding: 33px 0;
    border-radius: 17px 17px 0 0 !important;
    position: relative;
    .calendar-info {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 200;
    }
    .calendar-btn {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 200;
      .v-btn {
        border-radius: 8px;
        span {
          font-weight: bold;
        }
      }
    }
  }
  .month {
    color: #98aaca;
    font-size: 1.2rem;
    text-align: center;
    border-bottom: 1px solid #00a7b7;
    width: 85%;
    margin: auto;
  }
  .day-box {
    width: 45px;
    height: 45px;
    margin: 4px;
    text-align: center;
    border-radius: 50%;
    padding-top: 13px;
    font-size: 1.5rem;
    margin-right: 6px;
    position: relative;
    &:hover {
      background-color: #eeeeee;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: none;
    }
    &.freeTime {
      background-color: #00695daf;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }
    &.busyTime {
      background-color: #df6a6ac2;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
    }
    &.semiTime {
      background-color: #f7bf50d9;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
    }
    .semi-number {
      position: absolute;
      top: -5px;
      right: -3px;
      background-color: #e33838;
      min-width:20px;
      height:20px;
      border-radius: 50%;
      display:flex;
      align-items: center;
      justify-content: center;
      span {
        color: white;
        font-size: 10px;  
      }
    }
  }
  .weekday-box {
    width: 55px;
    height: 48px;
    text-align: center;
    border-radius: 50%;
    padding-top: 6px;
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.date-result {
  border-radius: 17px !important;
  background-color: rgba(243, 243, 243, 0.482) !important;
  .delete-icon:hover {
    color: red !important;
  }
  .times-box {
    border-radius: 5px !important;
    border: 1px solid rgb(221, 221, 221);
    height: 230px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 10px;
      margin: 0 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgb(236, 236, 236);
      opacity: 0.5;
      border-radius: 1px !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(185, 185, 185);
      width: 10px;
      border-radius: 1px !important;
    }
  }
  .selected-date {
    &:hover {
      color: #94afd8;
      // text-decoration: underline;
    }
  }
}
.table-row {
  background-color: #ffe0b2 !important;
}
@media only screen and (max-width: 600px) {
  .calendar {
    width: 270px;
    float: none;
    margin: 0 auto !important;

    .day-box {
      width: 30px;
      height: 30px;
      padding-top: 7px;
      margin: 1px;
    }
    .weekday-box {
      width: 32px;
      height: 32px;
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
</style>
